<template>
  <b-card-code>
    <b-card-text>
      <span class="faq-title">Perguntas frequentes</span>
    </b-card-text>

    <app-collapse accordion>
      <app-collapse-item class="question-title" title="O que é plano Premium ?">
        <span>
            Pie dragée muffin. Donut cake liquorice marzipan carrot cake topping powder candy. Sugar plum brownie
            brownie cotton candy. Tootsie roll cotton candy pudding bonbon chocolate cake lemon drops candy. Jelly
            marshmallow chocolate cake carrot cake bear claw ice cream chocolate. Fruitcake apple pie pudding jelly
            beans pie candy canes candy canes jelly-o. Tiramisu brownie gummi bears soufflé dessert cake.
        </span>
      </app-collapse-item>

      <app-collapse-item class="question-title" title="Como funcionam os pagamentos e quanto custa?">
        <span>
            Pode optar por uma subscrição do Dialog anual ou por pagar mensalmente. Utilize a ferramenta de calcular preço para estimar o
            valor a pagar consoante o número de equipamentos que deseja sincronizar. A qualquer momento pode cancelar a subscrição ou
            o editar o número de equipamentos associados ao seu plano. Caso opte por cancelar a subscrição continuará a ter acesso ao
            Dialog até que o período pago termine.
        </span>
      </app-collapse-item>

      <app-collapse-item class="question-title" title="Qual a diferença entre utilizadores?">
        <span>
            Pie dragée muffin. Donut cake liquorice marzipan carrot cake topping powder candy. Sugar plum brownie
            brownie cotton candy. Tootsie roll cotton candy pudding bonbon chocolate cake lemon drops candy. Jelly
            marshmallow chocolate cake carrot cake bear claw ice cream chocolate. Fruitcake apple pie pudding jelly
            beans pie candy canes candy canes jelly-o. Tiramisu brownie gummi bears soufflé dessert cake.
        </span>
      </app-collapse-item>
    </app-collapse>

    <template #code>
      {{ codeCollapseDefault }}
    </template>
  </b-card-code>
</template>

<script>
import { BCardText } from 'bootstrap-vue'
import BCardCode from '@core/components/b-card-code'
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'
import { codeCollapseDefault } from './code'

export default {
  components: {
    BCardCode,
    AppCollapse,
    AppCollapseItem,
    BCardText,
  },
  data() { return { codeCollapseDefault } },
}
</script>