<template>
  <div>
    <b-card class="plans-middle">
      <b-card-body>
        <b-row>
          <b-col xl="2" class="plans-pro">
            <div class="month-plans-pro">
              <div></div>
              <img src="@/assets/images/icons/plano-white.png" alt="">
              <span class="month-text">
                Premium
              </span>
            </div>
            <span class="month-description">Plano Premium</span>
          </b-col>
          <b-col xl="5" class="pro-time">
            <span class="pro-title">Plano Premium</span>
            <span class="pro-subtitle">Calcular preço</span>
            <div class="sinc-equip">
              <span class="sinc-title">Equipamentos sincronizados</span>
              <span class="sinc-description">Escolha quais os equipamentos que deseja ter sincronizados no Dialog.</span>
            </div>
            <div class="equip-edit">
              <input
                class="input-equip"
                type="number"
                placeholder="3"
              >
              <b-button
                variant="none"
                class="edit-btn-equip"
              >
                Edit
              </b-button>
            </div>
          </b-col>
          <b-col cols="12" offset-xl="1" xl="3" class="payment-section p-0">
            <b-tabs class="pay-tabs">
              <b-tab class="year-tab" active>
                <template #title>
                  <span class="pay-title">
                    Pagamento
                    <span class="title-bold">Anual</span>
                  </span>
                  <div class="desc">
                    <span class="desc-number">-16%</span>
                    <span class="desc-text">Desconto</span>
                  </div>
                </template>
                <b-card class="content-section">
                  <b-card-body>
                    <div class="plans-value">
                      <span class="month-value">
                        <span class="value-text">10</span>
                        <span class="value-simbol">€</span>/mês
                      </span>
                      <span class="total-value">Total ano 120€ / equipamento</span>
                      <span class="poupe-text">POUPE 24 €</span>
                      <hr>
                      <span class="premium-text">Dialog Premium</span>
                      <span class="premium-value">360 € / ano</span>
                      <b-button
                        variant="none"
                        class="plan-btn"
                        to="/company/payment"
                      >
                        Obter
                      </b-button>
                    </div>
                  </b-card-body>
                </b-card>
              </b-tab>
              <b-tab class="month-tab">
                <template #title>
                  <span class="pay-title">
                    Pagamento
                    <span class="title-bold">Mensal</span>
                  </span>
                </template>
                <b-card class="content-section">
                  <b-card-body>
                    <div class="plans-value">
                      <span class="month-value">
                        <span class="value-text">12</span>
                        <span class="value-simbol">€</span>/mês
                      </span>
                      <span class="total-value">Total ano 144€ / equipamento</span>
                      <span class="poupe-text">POUPE 24 €</span>
                      <hr>
                      <span class="premium-text">Dialog Premium</span>
                      <span class="premium-value">36 € / ano</span>
                      <b-button
                        variant="none"
                        class="plan-btn"
                      >
                        Obter
                      </b-button>
                    </div>
                  </b-card-body>
                </b-card>
              </b-tab>
            </b-tabs>
          </b-col>
        </b-row>
      </b-card-body>
    </b-card>
  </div>
</template>

<script>
import {
  BListGroup, BListGroupItem, BCardText, BButton, BRow, BCol, BFormGroup, 
  BFormInput, BForm, BTable, BCard, BCardHeader, BCardTitle, BFormCheckbox,
  BProgress, BProgressBar, BTab, BTabs,
} from 'bootstrap-vue'
import BCardCode from '@core/components/b-card-code'
import vSelect from 'vue-select'
import { useInputImageRenderer } from '@core/comp-functions/forms/form-utils'
import { ref } from '@vue/composition-api'

export default {
  components: {
    BButton,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BTable,
    BCard,
    BCardHeader,
    BCardTitle,
    BFormCheckbox,
    BCardCode,
    BListGroup,
    BCardText,
    BListGroupItem,
    BProgress,
    BProgressBar,
    BTab, 
    BTabs,
    vSelect,
  },
  props: {
    userData: {
      type: Object,
      required: true,
    },
  },
  setup(props) {

    const permissionsData = [
      {
        type: 'New for you',
        email: true,
        browser: false,
        app: false,
      },
      {
        type: 'Account activity',
        email: false,
        browser: true,
        app: false,
      },
      {
        type: 'A new browser used to sign in',
        email: true,
        browser: false,
        app: true,
      },
      {
        type: 'A new device is linked',
        email: false,
        browser: false,
        app: false,
      },
    ]

    // ? Demo Purpose => Update image on click of update
    const refInputEl = ref(null)
    const previewEl = ref(null)

    const { inputImageRenderer } = useInputImageRenderer(refInputEl, base64 => {
      // eslint-disable-next-line no-param-reassign
      props.userData.avatar = base64
    })

    return {
      permissionsData,
      inputImageRenderer,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>