<template>
  <section id="dashboard">
    <b-row class="match-height">
      <b-col
        lg="12"
        sm="12"
      >
        <user-plans-exp />
      </b-col>
    </b-row>

    <b-row class="match-height">
      <b-col
        lg="12"
        sm="12"
      >
        <user-plans-pro />
      </b-col>
    </b-row>

    <b-row class="match-height">
      <b-col
        lg="12"
        sm="12"
      >
        <user-faq />
      </b-col>
    </b-row>
  </section>
</template>

<script>
import { BRow, BCol } from 'bootstrap-vue'
import UserPlansExp from './UserPlansExp.vue'
import UserPlansPro from './UserPlansPro.vue'
import UserFaq from './UserFaq.vue'

export default {
  components: {
    BRow,
    BCol,
    UserPlansExp,
    UserPlansPro,
    UserFaq
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-company.scss';
</style>
