<template>
  <div>
    <b-card no-body class="account-top">
      <b-card-body>
        <div class="account-title">
            <feather-icon
              class="icon-account"
              icon="UserIcon"
            />
          <span class="text-account">
              Profile details
          </span>
        </div>
      </b-card-body>
    </b-card>
    <div class="account-body">
      <!-- Media -->
      <b-media class="mb-2">
        <template #aside>
          <b-avatar
            ref="previewEl"
            :src="userData.avatar"
            :variant="`light-${resolveUserRoleVariant(userData.role)}`"
            size="100px"
            circle
          />
        </template>
        <div class="d-flex flex-wrap">
          <b-button
            class="upload-btn"
            variant="none"
            @click="$refs.refInputEl.click()"
          >
            <input
              ref="refInputEl"
              type="file"
              class="d-none"
              @input="inputImageRenderer"
            >
            <span class="d-none d-sm-inline">Upload</span>
          </b-button>
        </div>
        <span class="allowed-files">Allowed file types: png, jpg, jpeg.</span>
      </b-media>

      <!-- User Info: Input Fields -->
      <b-form>
        <b-row>
          <b-col
            cols="12"
            md="6"
          >
            <!-- Field: Name -->
            <b-form-group
              label="First Name"
              label-for="user-first-name"
            >
              <b-form-input
                id="user-first-name"
                placeholder="First Name"
              />
            </b-form-group>
          </b-col>

          <b-col
            cols="12"
            md="6"
          >
            <b-form-group
              label="Last Name"
              label-for="user-last-name"
            >
              <b-form-input
                id="user-last-name"
                placeholder="Last Name"
              />
            </b-form-group>
          </b-col>

          <!-- Field: Email -->
          <b-col
            cols="12"
            md="6"
          >
            <b-form-group
              label="Email"
              label-for="email"
            >
              <b-form-input
                id="email"
                type="email"
                placeholder="Email"
              />
            </b-form-group>
          </b-col>

          <b-col
            cols="12"
            md="6"
          >
            <b-form-group
              label="Organization"
              label-for="organization"
            >
              <b-form-input
                id="organization"
                placeholder="Organization"
              />
            </b-form-group>
          </b-col>

          <!-- Field: Status -->
          <b-col
            cols="12"
            md="6"
          >
            <b-form-group
              label="Phone Number"
              label-for="user-phone"
            >
              <b-form-input
                id="user-phone"
                placeholder="Phone Number"
              />
            </b-form-group>
          </b-col>

          <b-col
            cols="12"
            md="6"
          >
            <b-form-group
                label="Address"
                label-for="user-address"
              >
                <b-form-input
                  id="user-address"
                  placeholder="Your Address"
                />
            </b-form-group>
          </b-col>

          <b-col
            cols="12"
            md="6"
          >
            <b-form-group
                label="State"
                label-for="state"
              >
                <b-form-input
                  id="state"
                  placeholder="State"
                />
              </b-form-group>
          </b-col>

          <b-col
            cols="12"
            md="6"
          >
            <b-form-group
                label="Zip Code"
                label-for="zipcode"
              >
                <b-form-input
                  id="zipcode"
                  placeholder="Code"
                />
              </b-form-group>
          </b-col>

          <b-col
            cols="12"
            md="6"
          >
            <b-form-group
                label="Country"
                label-for="country"
              >
                <v-select
                  id="country"
                  v-model="selectedContry"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="countryName"
                  :selectable="option => ! option.value.includes('select_country')"
                  label="text"
                />
              </b-form-group>
          </b-col>
          <b-col
            cols="12"
            md="6"
          >
            <b-form-group
                label-for="language"
                label="Language"
              >
                <v-select
                  id="language"
                  v-model="selectedLanguage"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :selectable="option => ! option.value.includes('select_language')"
                  :options="languageName"
                  label="text"
                />
              </b-form-group>
          </b-col>

          <b-col
            cols="12"
            md="6"
          >
            <b-form-group
                label-for="time-zone"
                label="Time zone"
              >
                <v-select
                  id="time-zone"
                  v-model="selectedTimeZone"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :selectable="option => ! option.value.includes('select_time')"
                  :options="timezoneName"
                  label="text"
                />
              </b-form-group>
          </b-col>
          <b-col
            cols="12"
            md="6"
          >
            <b-form-group
                label-for="currency"
                label="Currency"
              >
                <v-select
                  id="currency"
                  v-model="selectedCurrency"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :selectable="option => ! option.value.includes('select_currency')"
                  :options="currencyName"
                  label="text"
                />
              </b-form-group>
          </b-col>
        </b-row>
      </b-form>
      <div class="form-buttons">
        <b-button
          variant="none"
          class="form-btn mr-2"
          :block="$store.getters['app/currentBreakPoint'] === 'xs'"
        >
          Save Changes
        </b-button>
        <b-button
          variant="none"
          class="form-btn"
          :block="$store.getters['app/currentBreakPoint'] === 'xs'"
        >
          Discard
        </b-button>
      </div>
      <div class="delete-account">
        <h3>Delete Account</h3>
        <hr>
        <div class="col-lg-9 content-spacing-0">
          <b-alert
            class="alert-body"
            variant="none"
            show
          > 
            <h4 class="alert-heading">
                <feather-icon
                class="mr-25"
                icon="AlertTriangleIcon"
                />
                Are you sure you want to delete your account?
            </h4>
            <div class="alert-body">
                <span>Once you delete your account, there is no going back. Please be certain.</span>
            </div>
          </b-alert>
          <b-form-group>
            <b-form-checkbox
                id="register-privacy-policy"
                v-model="status"
                name="checkbox-1"
            >
                I confirm my account deactivation
            </b-form-checkbox>
          </b-form-group>
          <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            v-b-modal.modal-center
            variant="none"
            class="btn-deactivate mb-1 mb-sm-0 mr-0 mr-sm-1"
            :block="$store.getters['app/currentBreakPoint'] === 'xs'"
          >
          Deactivate Account
          </b-button>
        </div>
        <b-modal
          id="modal-center"
          centered
          cancel-title="Não quero cancelar"
          ok-title="Cancelar conta"
        >
          <div class="modal-text">
            <span class="modal-title">Delete Account</span>
            <span class="modal-description">
              Estamos tristes de o ver partir…<br/>
              Diga-nos porquê, todo feedback ajuda!
            </span>
          </div>
          <b-form-group>
            <b-form-checkbox
              class="check-delete"
              v-model="status"
              name="checkbox-1"
            >
                Problemas técnicos
            </b-form-checkbox>
            <b-form-checkbox
              class="check-delete"
              v-model="status"
              name="checkbox-1"
            >
              Não sei utilizar os dados e ferramentas
            </b-form-checkbox>
            <b-form-checkbox
              class="check-delete"
              v-model="status"
              name="checkbox-1"
            >
              A empresa fechou
            </b-form-checkbox>
            <b-form-checkbox
              class="check-delete"
              v-model="status"
              name="checkbox-1"
            >
              Faltam recursos que preciso
            </b-form-checkbox>
            <b-form-checkbox
              class="check-delete"
              v-model="status"
              name="checkbox-1"
            >
              Mudei para outro serviço
            </b-form-checkbox>
            <b-form-checkbox
              class="check-delete"
              v-model="status"
              name="checkbox-1"
            >
              Outra
            </b-form-checkbox>
            <b-form-textarea
              id="textarea-default"
              placeholder="Algo mais que gostaria de partilhar? (opcional)"
              rows="3"
            />
          </b-form-group>
        </b-modal>
      </div>
    </div>
  </div>
</template>

<script>
import {
  BButton, BMedia, BAvatar, BRow, BCol, BFormGroup, BFormInput, VBModal, BModal, BFormTextarea,
  BForm, BTable, BCard, BCardHeader, BCardTitle, BFormCheckbox, BAlert, BCardText, BLink,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { avatarText } from '@core/utils/filter'
import vSelect from 'vue-select'
import { useInputImageRenderer } from '@core/comp-functions/forms/form-utils'
import { ref } from '@vue/composition-api'
import useUsersList from './useUsersList'
import BCardCode from '@core/components/b-card-code'

export default {
  components: {
    BFormTextarea,
    BModal,
    BButton,
    BMedia,
    BAvatar,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BTable,
    BCard,
    BCardHeader,
    BCardTitle,
    BFormCheckbox,
    BAlert, 
    BCardText, 
    BLink,
    BCardCode,
    vSelect,
  },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  data() {
    return {
      selectedContry: 'Select Country',
      selectedLanguage: 'Select Language',
      selectedTimeZone: 'Select Time Zone',
      selectedCurrency: 'Select Currency',
      countryName: [
        // { value: 'select_country', text: 'Select Country' },
        { value: 'Russia', text: 'Russia' },
        { value: 'Canada', text: 'Canada' },
        { value: 'China', text: 'China' },
        { value: 'United States', text: 'United States' },
        { value: 'Brazil', text: 'Brazil' },
        { value: 'Australia', text: 'Australia' },
        { value: 'India', text: 'India' },
      ],
      languageName: [
        // { value: 'select_language', text: 'Select Language' },
        { value: 'English', text: 'English' },
        { value: 'Chinese', text: 'Mandarin Chinese' },
        { value: 'Hindi', text: 'Hindi' },
        { value: 'Spanish', text: 'Spanish' },
        { value: 'Arabic', text: 'Arabic' },
        { value: 'Malay', text: 'Malay' },
        { value: 'Russian', text: 'Russian' },
      ],
      timezoneName: [
        { value: 'select_time', text: 'Select Time Zone' },
      ],
      currencyName: [
        // { value: 'select_currency', text: 'Select Currency' },
        { value: 'EU', text: 'EUR' },
        { value: 'USA', text: 'USD' },
      ],
    }
  },
  props: {
    userData: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const { resolveUserRoleVariant } = useUsersList()

    const roleOptions = [
      { label: 'Admin', value: 'admin' },
      { label: 'Author', value: 'author' },
      { label: 'Editor', value: 'editor' },
      { label: 'Maintainer', value: 'maintainer' },
      { label: 'Subscriber', value: 'subscriber' },
    ]

    const statusOptions = [
      { label: 'Pending', value: 'pending' },
      { label: 'Active', value: 'active' },
      { label: 'Inactive', value: 'inactive' },
    ]

    const permissionsData = [
      {
        module: 'Admin',
        read: true,
        write: false,
        create: false,
        delete: false,
      },
      {
        module: 'Staff',
        read: false,
        write: true,
        create: false,
        delete: false,
      },
      {
        module: 'Author',
        read: true,
        write: false,
        create: true,
        delete: false,
      },
      {
        module: 'Contributor',
        read: false,
        write: false,
        create: false,
        delete: false,
      },
      {
        module: 'User',
        read: false,
        write: false,
        create: false,
        delete: true,
      },
    ]

    // ? Demo Purpose => Update image on click of update
    const refInputEl = ref(null)
    const previewEl = ref(null)

    const { inputImageRenderer } = useInputImageRenderer(refInputEl, base64 => {
      // eslint-disable-next-line no-param-reassign
      props.userData.avatar = base64
    })

    return {
      resolveUserRoleVariant,
      avatarText,
      roleOptions,
      statusOptions,
      permissionsData,

      //  ? Demo - Update Image on click of update button
      refInputEl,
      previewEl,
      inputImageRenderer,
    }
  },
  methods: {
    formSubmitted() {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Form Submitted',
          icon: 'EditIcon',
          variant: 'success',
        },
      })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/base/pages/page-company.scss';
</style>
